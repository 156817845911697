import {
  FileTextOutlined,
  GiftOutlined,
  GroupOutlined,
  LineChartOutlined,
  NotificationOutlined,
  ShopOutlined,
  ShoppingCartOutlined,
  SolutionOutlined,
  UserOutlined,
} from "@ant-design/icons";
import urls from "urls";

const shopNavTree = [
  {
    key: "shop",
    title: "shop",
    breadcrumb: false,
    submenu: [
      {
        key: "shop-reports",
        path: urls.reports,
        title: "reports",
        icon: LineChartOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "shop-products",
        title: "products",
        icon: ShoppingCartOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "shop-products-productList",
            path: urls.productList,
            title: "products",
            icon: "",
            breadcrumb: true,
            submenu: [],
          },
          {
            key: "shop-products-addProduct",
            path: urls.addProduct,
            title: "add_product",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: "shop-discount",
        title: "discounts",
        icon: GiftOutlined,
        breadcrumb: false,
        path: urls.discountCodes,
        submenu: [],
      },
      {
        key: "shop-orders",
        path: urls.ordersList,
        title: "order.orders",
        icon: FileTextOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "shop-orders-all",
            path: urls.ordersList,
            title: "order.orders",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "shop-orders-snelstart",
            path: urls.snelstartOrdersList,
            title: "snelstart_orders",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: "shop-companies",
        title: "companies",
        icon: ShopOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "shop-companies-existing",
            path: urls.customerList,
            title: "customers_all",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "shop-companies-snelstart",
            path: urls.snelstartCustomerList,
            title: "snelstart_customers",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "shop-companies-exact",
            path: urls.exactCustomerList,
            title: "exact_customers",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "shop-companies-pending",
            path: urls.customerListPending,
            title: "customers_pending",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: "shop-notifications",
        path: urls.notifications,
        title: "notifications_title",
        icon: NotificationOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "shop-customers",
        path: urls.userList,
        title: "users",
        icon: SolutionOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "shop-users",
            path: urls.userList,
            title: "shop_users",
            icon: UserOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "shop-admin",
            path: urls.adminList,
            title: "admin_users",
            icon: UserOutlined,
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: "shop-groups",
        path: urls.groups,
        title: "groups",
        icon: GroupOutlined,
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

const navigationConfig = [...shopNavTree];

export default navigationConfig;
